import React, { useEffect, useState } from 'react'
import Loading from '../../Global/Loading' // Import loading component
import { getReportsValues, getUserByToken } from '../../auth/core/_requests'
import MyBarChart from '../../../pages/Charts/TestBarChart'
import MyPieChart from '../../../pages/Charts/TestChart'
import { useAuth } from '../../../../app/modules/auth'

function DashboardArticulador() {
  const [dataSource, setDataSource] = useState(null)
  const [loading, setLoading] = useState(false)
  const [projects, setProjects] = useState([])
  const [selectedProjectId, setSelectedProjectId] = useState("");

  const fetchReports = async (projectId) => {
    setLoading(true);
    try {
      const responseData = await getReportsValues(projectId); // Pass projectId to the request
      setDataSource(responseData.data.data);
    } catch (err) {
      console.error("Error fetching reports data:", err);
    } finally {
      setLoading(false);
    }
  };

  const getUser = async () => {
    setLoading(true);
    try {
      const user = await getUserByToken();
      setProjects(user.data.data.projects);
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUser();
    fetchReports(null); // Fetch reports initially without filtering
  }, []);

  const handleFilterChange = (e) => {
    setSelectedProjectId(e.target.value);
  };

  const applyFilter = () => {
    if (selectedProjectId) {
      fetchReports(selectedProjectId); // Call fetchReports with selected project ID
    }
  };

  if (loading) {
    return <Loading /> // Stops here if loading
  }

  // console.log(dataSource);


  if (!dataSource || dataSource.length === 0) {
    return <h1>Erro ao carregar informação</h1> // Stops here if no dataSource
  }

  return (
    <>
      <div className="project-filter">
        <div className="filter-field">
          <label>Selecione um projeto para filtrar</label>
          <select
            value={selectedProjectId}
            onChange={handleFilterChange}
            className="form-control"
          >
            <option value="">Selecionar projeto</option>
            {projects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.name}
              </option>
            ))}
          </select>
        </div>
        <button
          className="app-btn green mt-2"
          style={{ cursor: 'pointer' }}
          onClick={applyFilter}
          disabled={!selectedProjectId}
        >
          Aplicar filtro
        </button>
      </div>
      <div className='d-flex'>

        <div className='row'>
          <div className='col-md-6'>
            {
              (dataSource.productivity_articulators.forms_completed > 0 ||
                dataSource.productivity_articulators.forms_not_completed > 0 ||
                dataSource.productivity_articulators.pending_cases > 0) ? (

                <MyBarChart
                  data={[
                    {
                      name: 'Formulários preenchidos',
                      formularios: dataSource.productivity_articulators.forms_completed,
                    },
                    {
                      name: 'Formulários não preenchidos',
                      formularios: dataSource.productivity_articulators.forms_not_completed,
                    },
                    {
                      name: 'Casos pendentes',
                      formularios: dataSource.productivity_articulators.pending_cases,
                    },
                  ]}
                  title='Produtividade dos Articuladores'
                />
              )
                :
                (
                  <p>Nenhum dado de Produtividade dos Articuladores</p>
                )
            }
          </div>
          <div className='col-md-6'>
            {
              (dataSource.referrals_and_returns_by_category.education.referrals > 0 ||
                dataSource.referrals_and_returns_by_category.education.returns > 0 ||
                dataSource.referrals_and_returns_by_category.social_assistance.referrals > 0 ||
                dataSource.referrals_and_returns_by_category.social_assistance.returns > 0 ||
                dataSource.referrals_and_returns_by_category.health.referrals > 0 ||
                dataSource.referrals_and_returns_by_category.health.returns > 0) ? (

                <MyBarChart
                  data={[
                    {
                      name: 'Educação',
                      encaminhamentos:
                        dataSource.referrals_and_returns_by_category.education.referrals,
                      retornos: dataSource.referrals_and_returns_by_category.education.returns,
                    },
                    {
                      name: 'Assistência Social',
                      encaminhamentos:
                        dataSource.referrals_and_returns_by_category.social_assistance.referrals,
                      retornos:
                        dataSource.referrals_and_returns_by_category.social_assistance.returns,
                    },
                    {
                      name: 'Saúde',
                      encaminhamentos: dataSource.referrals_and_returns_by_category.health.referrals,
                      retornos: dataSource.referrals_and_returns_by_category.health.returns,
                    },
                  ]}
                  title='Encaminhamentos e Retornos'
                />
              ) : (
                <p>Nenhum dado de Encaminhamentos e Retornos</p>
              )
            }
          </div>
          <div className="col-md-6">
            {
              (dataSource?.forms_vs_referrals?.forms_applied > 0 ||
                dataSource?.forms_vs_referrals?.referrals_generated > 0) ? (
                <MyPieChart data={[
                  {
                    name: 'Formulários',
                    value: dataSource?.forms_vs_referrals?.forms_applied
                  },
                  {
                    name: 'Encaminhamentos',
                    value: dataSource?.forms_vs_referrals?.referrals_generated
                  }]}
                  title='Formulários x Encaminhamentos' />
              ) : (
                <p>Nenhum dado de Formulários x Encaminhamentos</p>
              )
            }
          </div>
          <div className='col-md-6'>
            {
              dataSource?.main_visit_reasons[0].length > 0 ? (
                <MyPieChart
                  data={dataSource?.main_visit_reasons[0]?.slice(0, 6)
                    .filter(item => item.count > 0) // Example condition
                    .map(item => ({
                      name: item.reason,
                      value: item.count,
                      percentage: item.percentage,
                    })) || []}
                  title="Principais motivações para visitas"
                />
              ) : (
                <p>Nenhum dado de Principais motivações para visitas</p>

              )
            }
          </div>
        </div>

      </div>
    </>
  )
}

export default DashboardArticulador
